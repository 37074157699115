<template>
    <span v-if="this.indexLen > 1 && this.doTipDeletionsAndAdditions === true" @click="$emit('userCancelSelected', this.indexNum, this.recipientId, this.recipientDisplayname, this.recipientCurrencySymbol, this.recipientAmountChosen, true)" style="display: inline; outline:0.0em solid black; padding-top: 0vh; padding-bottom: 0vh">{{this.displaynameAndAmount}}<img :src="require('@/assets/cancel.png')" style="vertical-align: middle; line-height: 1vh; height: 3vh; width: 3vh; padding-top: 0vh; padding-bottom: 0.3vh"></span>
    <span v-if="this.indexLen < 2 || this.doTipDeletionsAndAdditions === false" style="display: inline; outline:0.0em solid black; padding-top: 0vh; padding-bottom: 0vh">{{this.displaynameAndAmount}}</span>
</template>

<script>
export default {
    name: 'RecipientConfirm',
     
    data () {
        return {
            displaynameAndAmount: "", 
        }
    },
    props:  {
        recipientId: String,
        recipientDisplayname: String,
        recipientCurrencySymbol: String,
        recipientAmountChosen: String,
        recipientCancelImageData: String,
        indexNum: Number,
        indexLen: Number,
        doTipDeletionsAndAdditions: Boolean,
    },
    computed: {
        recipientImageDataComputed() {
            return this.recipientCancelImageData;
        } 
    },
    mounted(){
        // console.log("this.recipientCancelImageData::: " + this.recipientCancelImageData);

        console.log("MY INDEX IS: " + this.indexNum + " index length is "  + this.indexLen + " displayname: " + this.recipientDisplayname + " amount : " + this.recipientAmountChosen + " time:: " + new Date().getTime());
        // console.log("this.recipientDisplayname: " + this.recipientDisplayname);
        // console.log("RECIPIENT recipientDiffCurrency: " + JSON.stringify(this.recipientDiffCurrency, null, 2));

 
        
          // console.log("this.tipsArray[i].recipient.displayname:: " + this.tipsArray[i].recipient.displayname + " this.tipsArray.length: " + this.tipsArray.length);
          if ((this.indexNum === this.indexLen - 1) && (this.indexLen > 1)){
            this.displaynameAndAmount = " & " + this.recipientDisplayname + " (" + this.recipientCurrencySymbol + this.recipientAmountChosen + ")" ;
          } else if (this.indexNum === this.indexLen - 2){
            this.displaynameAndAmount = " " + this.recipientDisplayname + " (" + this.recipientCurrencySymbol + this.recipientAmountChosen + ")";
          } else if (this.indexLen > 1){
            this.displaynameAndAmount = " " + this.recipientDisplayname + " (" + this.recipientCurrencySymbol + this.recipientAmountChosen + ")";
          } else {
            console.log("just the one recipient for now!");
            this.displaynameAndAmount = this.recipientDisplayname;
          }
        
    },

}
</script>

<style scoped>


</style>